import React from "react"
import Layout from "../../components/layout"
import { Link } from "gatsby"
import SEO from "../../components/seo"
import { Container, Row, Col, } from "react-bootstrap"
import dowDoc from "../../images/download-doc.png"
import felisw1detail from "../../images/Felis-W1.webp"
import felisw1Doc from '../../docs/Felis-W1.pdf'

class Felisw1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isToggleOn: true };
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    this.setState(state => ({
      isToggleOn: !state.isToggleOn
    }));
  }

  render() {
    return <Layout>
      <SEO title="Felis-W1 | Tumble Washer Controller" 
      description="Proalcs's basic model for industrial washing machines designed for heavy industrial conditions."/>
      <Container>
        <div className='prolacs-divider-title' style={{ marginTop: '0', backgroundColor: '#FFFFFF', display: 'flex', alignItems: 'baseline' }}>
          <br></br>
          <br></br>
          <Link className='link-breadcrumb' to='/products'><h2 style={{ fontSize: '19px' }}>Products /</h2> </Link>
          <h1 style={{ fontSize: '20px', paddingLeft: '5px' }}> Felis-W1 </h1>
        </div>
        <Row>
          <Col sm className='product-detail-head'>
            <div className={`product-detail-header-image´  ${this.state.isToggleOn ? 'product-detail-header-image' : 'product-detail-header-image-zoom'}`} >
              <img className='product-detail' onClick={this.handleClick} src={felisw1detail} alt="felis-w1-prolacs" />
            </div>
          </Col>
          <Col sm className='product-detail-title'>
            <div >
              <h1 className='controllerheadtext' style={{width:"100%"}} >Felis-W1</h1>
            </div>
            <h2>Tumble Washer Controller</h2>
            <p>It is an basic model for industrial washing machines designed for heavy industrial conditions. Resistant to
  vibration and dust. It has a total of 26 programs, 16 default and 10 user defined. It is an easy-to-use, standard
  series controller with its simplified Custom Design LCD display. Membrane keypad provides ease of use. The
  design of the control panel is customized according to the needs and designed in accordance with the brand
according to customer demands.</p>
          </Col>
        </Row>
        <Row>
          <Col sm className='product-detail-title'>
            <div >
              <h1 className='controllerheadtext' style={{width:"100%"}}>Documents</h1>
            </div>
            <br></br>
            <Row>
              <Col className='product-detail-doc-name'>
              <a   target="_blank" rel="noopener noreferrer"  href={felisw1Doc} >  <img className='product-detail-doc' src={dowDoc} alt="prolacs felis w1" />
              <h1>Felis-W1</h1>  </a>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className='prolacs-divider-title'>
          <br></br>
          <br></br>
          <h1>Specifications</h1>
        </div>
        <Row className='lms-inside-content'>
          <Col>
            <li>  Fast commissioning via direct connection.<br></br></li>
            <li>  Water level control without the need for an external sensor thanks to the on-board level sensor<br></br></li>
            <li>  Flexible user program support, diagnostic control and a wide list of parameters.<br></br></li>
            <li>  Suitable for heating / steam heating.<br></br></li>
            <li>  Suitable for Coin Operated.<br></br></li>
          </Col>
        </Row>
        <div className=''>
          <br></br>
          <br></br>
          <h1 >Features</h1>
        </div>
        <div>
          <h1 className='controllerheadtext' style={{width:"100%"}}>Inputs/Outputs</h1>
        </div>

        <Row className='product-table-row'>
          <Col>
            <p>Analog Input</p>
          </Col>
          <Col>
            <p>Onboard Level Sensor (Hydrostatic 0-100 cm) </p>
            <p>Temperature Sensor Input (-10~100 °C)</p>
          </Col>
          <Col className='lastp'>
            <p>1 </p>
            <p>1 </p>
          </Col>
        </Row>
        <Row className='product-table-row'>
          <Col>
            <p>Analog Output</p>
          </Col>
          <Col>
            <p>AC Drive Control 0~10V DC
         </p>
          </Col>
          <Col className='lastp'>
            <p>1</p>
          </Col>
        </Row>
        <Row className='product-table-row'>
          <Col>
            <p>Digital Input</p>
          </Col>
          <Col>
            <p>5-24V DC</p>
          </Col>
          <Col className='lastp'>
            <p>4</p>
          </Col>
        </Row>
        <Row className='product-table-row'>
          <Col>
            <p>Digital Output</p>
          </Col>
          <Col>
            <p>250V AC 1A Relay NA</p>
          </Col>
          <Col className='lastp'>
            <p>13</p>
          </Col>
        </Row>

        <div >
          <h1 className='controllerheadtext' style={{width:"100%"}}>Programming</h1>
        </div>
        <Row className='product-table-row'>
          <Col>
            <p>Program Count</p>
          </Col>
          <Col>
            <p>16 Default  Programs <br></br>
              10 User Programs</p>
          </Col>
          <Col className='lastp'>
            <p>26</p>
          </Col>
        </Row>
        <div>
          <h1 className='controllerheadtext' style={{width:"100%"}}>Technical Specifications</h1>
        </div>
        <Row className='product-table-row'>
          <Col>
            <p>Operating Voltage</p>
          </Col>
          <Col>
            <p>12 V AC/DC</p>
          </Col>
          <Col className='lastp'>
          </Col>
        </Row>
        <Row className='product-table-row'>
          <Col>
            <p>Operating Temperature</p>
          </Col>
          <Col>
            <p>0~60 °C</p>
          </Col>
          <Col className='lastp'>
          </Col>
        </Row>
        <Row className='product-table-row'>
          <Col>
            <p>Operating Humidty</p>
          </Col>
          <Col>
            <p>%10 - 85 (Non-Condensing)</p>
          </Col>
          <Col className='lastp'>
          </Col>
        </Row>
        <Row className='product-table-row'>
          <Col>
            <p>Keyboard</p>
          </Col>
          <Col>
            <p>7 Keys</p>
          </Col>
          <Col className='lastp'>
          </Col>
        </Row>
        <Row className='product-table-row'>
          <Col>
            <p>Display</p>
          </Col>
          <Col>
            <p>45x32 mm Custom Designed LCD</p>
          </Col>
          <Col className='lastp'>
          </Col>
        </Row>
      </Container >
    </Layout >
  }
};

export default Felisw1
